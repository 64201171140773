import React from 'react';

const Footer = () => (
  <footer className="footer">
    <div className="container text-center">
      <p className="text">
        <span className="title">Like What You See?</span> If you are a business owner or consumer, please&nbsp;
        <a href="http://www.opinionshield.com" target="_blank" rel="noopener noreferrer">
          click here
        </a>
        &nbsp;to learn more.
      </p>
    </div>
  </footer>
);

export default Footer;
