import React from 'react';

const Navbar = () => (
  <header>
    <nav className="navbar">
      <div className="container">
        <img className="logo" src="/img/opinionshield-logo.png" alt="OpinionShield Logo" />
      </div>
    </nav>
  </header>
);

export default Navbar;
