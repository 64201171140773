import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import '../styles/main.scss';
import RenderComponent from './render';
import Business from './components/business';
import JumbotronError from './components/jumbotron-error';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/:id" component={RenderComponent(Business)} />
      <Route component={RenderComponent(JumbotronError)} />
    </Switch>
  </BrowserRouter>
);

export default App;
