import React from 'react';
import PropTypes from 'prop-types';
import {compose, withStateHandlers, withState, withHandlers} from 'recompose';
import {withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow} from 'react-google-maps';
import isEmpty from 'lodash/isEmpty';

const {GOOGLE_API_KEY} = process.env;
const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;

const Map = ({geometry: {location = {}}, name: businessName}) => {
  if (!isEmpty(location)) {
    const {lat: mapLat, lng: mapLng} = location;

    const MapWithAMakredInfoWindow = compose(
      withStateHandlers(
        () => ({
          isOpen: false,
          name: businessName,
          lat: parseFloat(mapLat),
          lng: parseFloat(mapLng)
        }),
        {
          onToggleOpen: ({isOpen}) => () => ({
            isOpen: !isOpen
          })
        }
      ),
      withState('zoom', 'onZoomChange', 17),
      withHandlers(() => {
        const refs = {
          map: undefined
        };

        return {
          onMapMounted: () => ref => {
            refs.map = ref;
          },
          onZoomChanged: ({onZoomChange}) => () => {
            onZoomChange(refs.map.getZoom());
          }
        };
      }),
      withScriptjs,
      withGoogleMap
    )(({lat, lng, onToggleOpen, name, isOpen, onZoomChanged, zoom, onMapMounted}) => (
      <GoogleMap defaultCenter={{lat, lng}} zoom={zoom} onZoomChanged={onZoomChanged} ref={onMapMounted}>
        <Marker position={{lat, lng}} onClick={onToggleOpen} icon="../../../img/marker-icon.png">
          {isOpen && (
            <InfoWindow onCloseClick={onToggleOpen}>
              <div>{name}</div>
            </InfoWindow>
          )}
        </Marker>
      </GoogleMap>
    ));

    return (
      <MapWithAMakredInfoWindow
        googleMapURL={GOOGLE_MAP_URL}
        loadingElement={<div style={{height: '100%', padding: '2rem'}} />}
        containerElement={<div style={{height: '200px', padding: '2rem', backgroundColor: '#FFFFFF'}} />}
        mapElement={<div style={{height: '100%'}} />}
      />
    );
  }
  return <img src="/img/map.png" alt="Business Location" />;
};

Map.defaultProps = {
  geometry: {},
  name: 'Business Location'
};

Map.propTypes = {
  geometry: PropTypes.object,
  name: PropTypes.string
};

export default Map;
