import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import Map from './map';

const ClaimedBusiness = ({businessPhotoUri, geometry, shareDetails, name}) => (
  <div className="claimed-business">
    <Col xs={12}>
      <Row className="is-table-row">
        <Col sm={5} id="business-details">
          <h3 className="title">From the Business</h3>
          <p className="text">{shareDetails}</p>
          <Map geometry={geometry} name={name} />
        </Col>
        <Col sm={7} className="claimed-business-image" id="business-photo">
          <img src={businessPhotoUri} alt="Business Description" className="img-responsive" />
        </Col>
      </Row>
    </Col>
  </div>
);

ClaimedBusiness.propTypes = {
  businessPhotoUri: PropTypes.string.isRequired,
  geometry: PropTypes.object.isRequired,
  shareDetails: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default ClaimedBusiness;
