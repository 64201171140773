import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Row, Clearfix} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';

import Jumbotron from './jumbotron';
import JumbotronError from './jumbotron-error';
import UnclaimedBusiness from './unclaimed-business';
import ClaimedBusiness from './claimed-business';

import {getMerchantInfo, getMerchantStats} from '../services/opinionshield-api';

const {OPINIONSHIELD_API_URL} = process.env;

class Merchant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: '',
      businessPhotoUri: '',
      category: '',
      city: '',
      claimed: false,
      contactEmail: '',
      geometry: {},
      name: '',
      phoneNumber: '',
      profilePhotoUri: '',
      shareDetails: '',
      state: '',
      website: '',
      zip: '',
      cbr: 0,
      ratings: 0,
      error: false,
      showComingSoon: false
    };
  }

  componentDidMount() {
    const {
      match: {
        params: {id}
      }
    } = this.props;
    // get the merchant's info
    getMerchantInfo(id)
      .then(info => {
        const {data: merchantInfo} = info;

        if (!merchantInfo.businessPhotoUri && !merchantInfo.profilePhotoUri) {
          merchantInfo.showComingSoon = true;
        }

        if (merchantInfo.profilePhotoUri) {
          merchantInfo.profilePhotoUri = `${OPINIONSHIELD_API_URL}${merchantInfo.profilePhotoUri}`;
        } else {
          merchantInfo.profilePhotoUri = '/img/merchant.png';
        }

        if (merchantInfo.businessPhotoUri) {
          merchantInfo.businessPhotoUri = `${OPINIONSHIELD_API_URL}${merchantInfo.businessPhotoUri}`;
        } else {
          merchantInfo.businessPhotoUri = '/img/business-image.jpg';
        }

        this.setState(merchantInfo, () => {
          getMerchantStats(id).then(stats => {
            const {data: merchantStats} = stats;
            this.setState(merchantStats);
          });
        });
      })
      .catch(() => this.setState({error: true}));
  }

  render() {
    const {
      address,
      businessPhotoUri,
      category,
      city,
      claimed,
      contactEmail,
      name,
      geometry,
      phoneNumber,
      profilePhotoUri,
      shareDetails,
      state,
      website,
      zip,
      cbr,
      ratings,
      error,
      showComingSoon
    } = this.state;

    if (error) {
      return <JumbotronError />;
    }

    return (
      <div>
        <Jumbotron
          address={address}
          category={category}
          city={city}
          contactEmail={contactEmail}
          name={name}
          phoneNumber={phoneNumber}
          profilePhotoUri={profilePhotoUri}
          state={state}
          website={website}
          zip={zip}
          cbr={cbr}
          ratings={ratings}
        />
        <Clearfix />
        <Row className="description">
          {claimed && !showComingSoon ? (
            <ClaimedBusiness
              businessPhotoUri={businessPhotoUri}
              shareDetails={shareDetails}
              geometry={geometry}
              name={name}
            />
          ) : (
            <UnclaimedBusiness geometry={geometry} showComingSoon={showComingSoon} claimed={claimed} name={name} />
          )}
        </Row>
      </div>
    );
  }
}

Merchant.defaultProps = {
  match: {}
};

Merchant.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
};

export default withRouter(Merchant);
