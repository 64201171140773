import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

const Jumbotron = ({
  address,
  category,
  city,
  contactEmail,
  name,
  phoneNumber,
  profilePhotoUri,
  state,
  website,
  zip,
  cbr,
  ratings
}) => {
  let icon = category.replace('/', '-');
  icon = icon.replace(' ', '-');

  return (
    <div className="jumbotron">
      <Row className="is-table-row">
        <Col md={2} className="profile">
          <div className="profile-container">
            <img src={profilePhotoUri} className="profile-image" alt="Merchant profile" />
          </div>
        </Col>
        <Col md={7} className="information">
          <h2 className="title">
            {name}
            &nbsp;
            <small>
              <span className={`icons-categories icons-categories-${icon.toLowerCase()}`} />
              &nbsp;&nbsp;
              {category}
            </small>
          </h2>
          <p>
            {address}, {city}, {state} {zip}
          </p>
          <p>{phoneNumber}</p>
          <a className="link" href={`mailto:${contactEmail}`}>
            {contactEmail}
          </a>
          <a className="link" href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        </Col>
        <Col md={3} className="cbr">
          <div className="cbr-container">
            <p className="title">Consumer Business Rating (CBR)</p>
            <p className="value">{Math.ceil(cbr)}</p>
            <p className="number">
              {ratings.toLocaleString()}
              &nbsp;ratings
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

Jumbotron.propTypes = {
  address: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  contactEmail: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  profilePhotoUri: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  website: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  cbr: PropTypes.number.isRequired,
  ratings: PropTypes.number.isRequired
};

export default Jumbotron;
