import React from 'react';
import {Row, Col} from 'react-bootstrap';

const Jumbotron = () => (
  <div className="jumbotron">
    <Row className="is-table-row">
      <Col md={2} className="profile">
        <div className="profile-container">
          <img src="/img/merchant.png" className="profile-image" alt="Default merchant profile" />
        </div>
      </Col>
      <Col md={7} className="information">
        <h2 className="title">404</h2>
        <p />
        <p>This is not the web page you are looking for.</p>
      </Col>
      <Col md={3} />
    </Row>
  </div>
);

export default Jumbotron;
