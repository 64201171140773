import React from 'react';

import NavBar from './components/navbar';
import Footer from './components/footer';

export default function(ComposedComponent) {
  const RenderComponent = () => (
    <div>
      <NavBar />

      <div className="container">
        <div className="merchant-public-page">
          <ComposedComponent />
        </div>
      </div>

      <Footer />
    </div>
  );

  return RenderComponent;
}
