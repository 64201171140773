import React from 'react';
import {Row, Col} from 'react-bootstrap';

const ClaimBusiness = () => (
  <div className="claim-business">
    <Row>
      <Col md={3} className="shield">
        <div className="shield-container">
          <img src="/img/claim.png" alt="Claim business" />
        </div>
      </Col>
      <Col md={9} className="claim-business-container">
        <h3 className="title">Is this your business? Claim It!</h3>
        <p className="text">
          As the owner of this business you can connect to a merchant app for free to manage your profile.
        </p>
        <a href="mailto:info@opinionshield.com" className="link">
          CLAIM NOW
        </a>
      </Col>
    </Row>
  </div>
);

export default ClaimBusiness;
