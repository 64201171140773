import React from 'react';
import {Row, Col} from 'react-bootstrap';

const ComingSoon = () => (
  <div className="claim-business">
    <Row>
      <Col md={3} className="shield">
        <div className="shield-container">
          <img src="/img/claim.png" alt="Claim business" />
        </div>
      </Col>
      <Col md={9} className="claim-business-container">
        <h3 className="title">Coming Soon!</h3>
        <p className="text">
          The owner of this business is working on adding information to ther OpinionShield profile.
          <br />
          Please check back here soon for updates!
        </p>
      </Col>
    </Row>
  </div>
);

export default ComingSoon;
