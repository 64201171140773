import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import ClaimBusiness from './claim-business';
import ComingSoon from './coming-soon';
import Map from './map';

const UnclaimedBusiness = ({geometry, showComingSoon, claimed, name}) => {
  let claimNow = <ClaimBusiness />;

  if (claimed && showComingSoon) {
    claimNow = <ComingSoon />;
  }

  return (
    <div className="unclaimed-business">
      <Col xs={12}>
        <Row className="is-table-row">
          <Col sm={5}>
            <Map geometry={geometry} name={name} />
          </Col>
          <Col sm={7} id="claim-now">
            {claimNow}
          </Col>
        </Row>
      </Col>
    </div>
  );
};

UnclaimedBusiness.propTypes = {
  geometry: PropTypes.object.isRequired,
  showComingSoon: PropTypes.bool.isRequired,
  claimed: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired
};

export default UnclaimedBusiness;
