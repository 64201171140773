import axios from 'axios';

const {OPINIONSHIELD_API_URL} = process.env;

export const getMerchantInfo = id =>
  axios({
    method: 'get',
    baseURL: OPINIONSHIELD_API_URL,
    url: `api/Merchants/${id}/public-info`
  });

export const getMerchantStats = id =>
  axios({
    method: 'get',
    baseURL: OPINIONSHIELD_API_URL,
    url: `api/Merchants/${id}/public-stats`
  });
